<template>
    <v-form ref="form">
        <v-row class="ml-3 mt-6">
            <v-col cols="12" lg="3" xl="2">
                 <v-select
                    class="pr-4"
                    v-model="selectedApplicableOptions"
                    :items="applicableOptions"
                    clearable
                    variant="underlined"
                    label="Applicable to"
                >
                    <template v-slot:selection="{ item }">
                        <v-chip size="small" color="accent" variant="outlined">{{ item.raw.toUpperCase() }}</v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            title
                        >
                            <v-chip size="small" color="accent" variant="outlined">{{ item.raw.toUpperCase() }}</v-chip>
                        </v-list-item>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" lg="5" xl="3" v-if="selectedApplicableOptions && selectedApplicableOptions != 'default'">
                <v-autocomplete v-if="selectedApplicableOptions == 'provider'"
                    class="pr-4"
                    v-model="value.applicable_providers"
                    :items="externalProviders"
                    label="Applicable providers"
                    :rules="[v => (!!v && !!v.length) || 'Applicable provider is required']"
                    multiple
                    variant="underlined"
                >
                    <template v-slot:selection="{ item }">
                        <v-chip size="small" color="accent" variant="outlined">{{ item.raw.country_code }}-{{ item.raw.party_id }}</v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                        <v-list-item v-bind="props">
                            <template v-slot:title></template>
                            <template v-slot:prepend="{ isSelected }">
                                <v-list-item-action start>
                                    <ProviderSelectorTemplate 
                                        multiple
                                        :provider="item.raw"
                                        :isSelected="isSelected"
                                    />
                                </v-list-item-action>
                            </template>
                        </v-list-item>
                    </template>
                </v-autocomplete>
                <v-autocomplete v-if="selectedApplicableOptions == 'hub'"
                    class="pr-4"
                    v-model="value.applicable_user"
                    :items="hubs"
                    label="Applicable user"
                    :rules="[v => !!v || 'Applicable user is required']"
                    variant="underlined"
                >
                    <template v-slot:selection="{ item }">
                        <v-chip size="small" color="accent" variant="outlined">{{ item.title.toUpperCase() }}</v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            title
                        >
                            <v-chip size="small" color="accent" variant="outlined">{{ item.title.toUpperCase() }}</v-chip>
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="4" xl="3">
                <v-list-item-subtitle class="pl-0">
                    Priority
                </v-list-item-subtitle>
                <v-row class="mx-0 pt-4 pr-4" justify="center">
                    <PriorityIcon :priority="value.priority || 1" :max="9" :min="1"></PriorityIcon>
                    <v-slider
                        class="pt-2"
                        :max="9"
                        :min="1"
                        :step="1"
                        v-model="value.priority"
                        thumb-label
                        density="compact"
                        color="accent"
                    >
                    </v-slider>
                </v-row>
            </v-col>
            <v-col cols="12" md="12">
                <v-text-field class="pr-6"
                    v-model="value.applicable_query"
                    label="Applicable query"
                    variant="underlined"
                ></v-text-field>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import PriorityIcon from "@/components/icons/PriorityIcon"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"

export default {
    name: "tariff-configurations-edit",
    props: {
        value: {
            required: true
        }
    },
    components: {
        PriorityIcon,
        ProviderSelectorTemplate
    },
    inject: ['$api'],
    data() {
      return {
        applicableOptions: ['hub', 'provider', 'default'],
        selectedApplicableOptions: 'default',
        externalProviders: [],
        hubs: []
      }
    },
    async mounted(){
      this.loadProviders()
      this.loadHubs()
      this.loadSelectedApplicableOptions(this.value)
    },
    beforeUpdate () {
        this.loadSelectedApplicableOptions(this.value)
    },
    watch: {
        selectedApplicableOptions(newVal) {
            if (newVal === 'provider') {
                this.value.applicable_user = null
            } else if (newVal === 'hub') {
                this.value.applicable_providers = null
            } else if (newVal === 'default') {
                this.value.applicable_providers = null
                this.value.applicable_user = null
            }
        }
    },
    methods: {
        validate(){
            return this.$refs.form.validate()
        },
        loadProviders(){
            this.$api.get("/providers-external")
            .then(res => {
                this.externalProviders = res.data
                    .filter(p => p.role && (p.role.indexOf('EMSP') > -1 || p.role.indexOf('NSP') > -1))
                    .map(p => { 
                        return {
                            ...p,
                            title: p.role ? `${p.country_code}-${p.party_id} ${p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.user.username}`, 
                            value: p.id
                        }
                    })
            })
            .catch(this.showError)
        },
        loadHubs(){
            this.$api.get("/hubs")
            .then(res => {
                return this.hubs = res.data.map(hub => { 
                        return {
                            value: hub.id,
                            title: hub.username
                        }
                    })
            })
            .catch(this.showError)
        },
        loadSelectedApplicableOptions(input){
            if (!input) return

            if (input.applicable_providers && this.selectedApplicableOptions !== 'provider') {
                this.selectedApplicableOptions = 'provider'
                this.value.applicable_user = null
            }
            
            if (input.applicable_user && this.selectedApplicableOptions !== 'hub') {
                this.selectedApplicableOptions = 'hub'
                this.value.applicable_providers = null
            }
        }
    }
}
</script>