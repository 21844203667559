<template>
    <v-container fluid>

        <AppBar title="Charge request errors" icon="mdi-badge-account-alert" />

        <!-- Table filters -->
        <v-row class="filer-row">
            <v-col cols="12" md="2">
                <v-autocomplete
                    class="filter-field accent"
                    v-model="selectedSender"
                    :items="providers"
                    label="Sender CPO"
                    variant="outlined"
                    clearable
                    hide-details
                >
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            title
                        >
                            <ProviderSelectorTemplate :provider="item.raw" />
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>

            <v-col cols="12" md="2">
                <v-autocomplete
                    class="filter-field accent"
                    v-model="selectedReceiver"
                    :items="providers"
                    label="Receiver EMSP"
                    variant="outlined"
                    clearable
                    hide-details
                >
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            title
                        >
                            <ProviderSelectorTemplate :provider="item.raw" />
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>

            <v-col cols="12" md="2">
                <v-select
                    class="filter-field accent"
                    v-model="selectedType"
                    :items="[ 'RTA', 'COMMAND' ]"
                    label="Type"
                    variant="outlined"
                    clearable
                    hide-details
                />
            </v-col>

            <v-col cols="12" md="2">
                <v-menu
                    ref="menu"
                    class="filter-field"
                    v-model="showDateFromPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    offset-y
                    hide-details
                >
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            class="filter-field accent"
                            v-model="selectedDateFrom"
                            label="Date from"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="props"
                            variant="outlined"
                            clearable
                            hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDateFromPicker"
                        @update:modelValue="() => {
                            selectedDateFrom = this.dateFormat(this.selectedDateFromPicker.toISOString())
                            showDateFromPicker = false
                        }"
                        no-title
                        hide-details
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" md="2">
                <v-menu
                    ref="menu"
                    class="filter-field"
                    v-model="showDateToPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    offset-y
                    hide-details
                >
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            class="filter-field accent"
                            v-model="selectedDateTo"
                            label="Date to"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="props"
                            variant="outlined"
                            clearable
                            hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDateToPicker"
                        @update:modelValue="() => {
                            selectedDateTo = this.dateFormat(this.selectedDateToPicker.toISOString())
                            showDateToPicker = false
                        }"
                        no-title
                        hide-details
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <SearchButton
                :loading="loading"
                :searchFunction="onFilter"
            />
        </v-row>

        <v-data-table
            class="accent-table rounded-table"
            :items="chargeRequests"
            :headers="[
                { title: 'EMSP', key: 'sender_provider_id' },
                { title: 'CPO', key: 'receiver_provider_id' },
                { title: 'Type', key: 'type' },
                { title: 'Token', key: 'token_uid' },
                { title: 'Location', key: 'location' },
                { title: 'Error', key: 'error' },
                { title: 'Created at', key: 'created_at' },
                { key: 'actions', align: 'end' },
            ]" 
            :loading="loading"
            items-per-page="25"
            density="compact"
        >
            <template v-slot:item.sender_provider_id="{ item }">
                <v-chip v-if="item.sender_provider_id === 1" 
                    class="bg-secondary-chip text-capitalize" 
                    variant="flat" 
                    size="small"
                >
                    Wenea
                </v-chip>
                <strong v-else>
                    <ProviderName
                        :provider="providers.find(p => p.id == item.sender_provider_id)"
                        role="cpo"
                    />
                </strong>
            </template>
            <template v-slot:item.receiver_provider_id="{ item }">
                <v-chip v-if="item.receiver_provider_id === 1" 
                    class="bg-secondary-chip text-capitalize" 
                    variant="flat" 
                    size="small"
                >
                    Wenea
                </v-chip>
                <strong v-else-if="item.receiver_provider_id">
                    <ProviderName
                        :provider="providers.find(p => p.id == item.receiver_provider_id)"
                        role="emsp"
                    />
                </strong>
                <strong v-else>NOT_FOUND</strong>
            </template>
            <template v-slot:item.location="{ item }">
                <LocationName
                    v-if="item.location_id"
                    :location="{ id: item.location_id, name: item.location_name }"
                    type="table"
                />
            </template>
            <template v-slot:item.error="{ item }">
                <json-viewer 
                    v-if="item.error" 
                    :value="JSON.parse(item.error)"
                />
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ dateFullFormat(item.created_at) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn
                    icon="mdi-magnify" variant="text" size="small"
                    @click="showInDialog(item)"
                />
            </template>
        </v-data-table>

        <JsonDialog />
    </v-container>
</template>

<script>
import { EventBus } from "@/event-bus"
import AppBar from "@/components/AppBar"
import LocationName from "@/components/location/LocationName"
import ProviderName from "@/components/provider/ProviderName"
import SearchButton from "@/components/ui-components/SearchButton.vue"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"
import JsonDialog from "@/components/JsonDialog"
import _ from "lodash"

export default {
    name: "charge-requests-error",
    components: {
        AppBar,
        LocationName,
        ProviderName,
        SearchButton,
        ProviderSelectorTemplate,
        JsonDialog
    },
    inject: ['$api'],
    data() {
        return {
            providers: [],
            chargeRequests: [],
            loading: false,
            selectedSender: undefined,
            selectedReceiver: undefined,
            selectedType: undefined,

            selectedDateFrom: null,
            selectedDateFromPicker: null,
            showDateFromPicker: false,

            selectedDateTo: null,
            selectedDateToPicker: null,
            showDateToPicker: false,
        }
    },
    mounted() {
        this.$api.get("/providers")
            .then((res) => { this.providers = res.data.map(p => { 
                    const title = `${p.country_code}-${p.party_id} ${p.company?.name || p.user?.company?.name || p.user?.username}`
                    return { 
                        ...p, 
                        title: p.role ? `${title} (${p.role})` : title, 
                        value: p.id 
                    } 
                }) })
            .catch(this.showError)
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query': {
            handler(params) {
                if (_.isEmpty(params) && !this.selectedDate) {
                    this.selectedDateFrom = this.dateFormat(new Date().toISOString())
                }
                this.routeFilters(params)
                this.loadChargeRequests()
            },
            immediate: true
        }
    },
    methods: {
        onFilter() {
            if (this.loading) return
            this.urlPush('sender', this.selectedSender)
            this.urlPush('receiver', this.selectedReceiver)
            this.urlPush('type', this.selectedType)
            this.urlPush('dateFrom', this.selectedDateFrom)
            this.urlPush('dateTo', this.selectedDateTo)
            
            this.loadChargeRequests()
        },
        async loadChargeRequests() {
            this.loading = true

            let queryParams = ''
            if (this.selectedSender) { queryParams += `&sender_provider_id=${this.selectedSender}` }
            if (this.selectedReceiver) { queryParams += `&receiver_provider_id=${this.selectedReceiver}` }
            if (this.selectedType) { queryParams += `&type=${this.selectedType}` }
            if (this.selectedDateFrom) { queryParams += `&date_from=${this.selectedDateFrom}` }
            if (this.selectedDateTo) { queryParams += `&date_to=${this.selectedDateTo}` }

            await this.$api.get(`charge-request-errors?${queryParams}`)
                .then(resp => this.chargeRequests = resp.data)
                .catch(this.showError)
                .then(_ => this.loading = false)
        },
        async showInDialog(chargeRequest) {
            const requestToShow = await this.$api
                .get(`charge-request-errors/${chargeRequest.id}?type=${chargeRequest.type}`)
                .then(resp => resp.data)
            
            const title = chargeRequest.type == 'RTA' 
                ? 'RTA request' 
                : `Command Start #${chargeRequest.id}`

            EventBus().emit(
                "dialog-json:open",
                { title, json: requestToShow, maxWidth: 800 }
            )
        }
    }
}
</script>
