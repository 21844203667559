<template>
  <v-container fluid>
    <AppBar title="Tools" icon="mdi-tools" />

    <v-expansion-panels
      v-model="expanded"
    >

      <v-expansion-panel>
        <v-expansion-panel-title>
          <div class="d-flex align-center">
            <v-icon color="accent" size="small">mdi-tag-remove-outline</v-icon>
            <h4 class="ml-2">Providers without assigned tariffs</h4>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pa-4">
          <p class="mb-6">
              List all <v-chip size="small" variant="outlined">EMSP</v-chip> providers that have a Wenea connector without an associated tariff. It also shows as not associated if they have a tariff in REMOVED status associated.
          </p>
          <ProvidersWithoutTariffs></ProvidersWithoutTariffs>
        </v-expansion-panel-text>
      </v-expansion-panel>

     <v-expansion-panel>
        <v-expansion-panel-title>
          <div class="d-flex align-center">
            <v-icon color="accent" size="small">mdi-tag-multiple-outline</v-icon>
            <h4 class="ml-2">Collided tariffs</h4>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pa-4">
          <p class="mb-2 mt-1">
            List tariffs that match in provider, connector and priority.
          </p>
          <CollidedTariffs></CollidedTariffs>
        </v-expansion-panel-text>
      </v-expansion-panel>
 
      <v-expansion-panel>
        <v-expansion-panel-title>
          <div class="d-flex align-center">
            <v-icon color="accent" size="small">mdi-calculator-variant-outline</v-icon>
            <h4 class="ml-2">Charge session price calculator</h4>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pa-4">
          <p class="mb-2">
            This tool calculates what pertocarry tariff would be for this connector and provider, and estimates price of charge.
          </p>
          <ChargeSessionPriceCalculator max-width="800px"></ChargeSessionPriceCalculator>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title>
          <div class="d-flex align-center">
            <v-icon color="accent" size="small">mdi-credit-card-remove-outline</v-icon>
            <h4 class="ml-2 mt-1">Charge sessions without CDR</h4>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pa-4">
          <p class="mb-2">
            List all sessions with more than 0.2kWh that do not have an associated CDR.
          </p>
          <SessionsWithoutCdrs></SessionsWithoutCdrs>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title>
          <div class="d-flex align-center">
            <v-icon color="accent" size="small">mdi-alphabetical-variant</v-icon>
            <h4 class="ml-2">evse_id parser</h4>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pa-4">
          <EvseIdReferencesTool></EvseIdReferencesTool>
        </v-expansion-panel-text>
      </v-expansion-panel>
 
      <v-expansion-panel>
        <v-expansion-panel-title>
          <div class="d-flex align-center">
            <v-icon color="accent" size="small">mdi-database-import-outline</v-icon>
            <h4 class="ml-2">Import Sessions from DTM</h4>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pa-4">
            <p>
              Tool to import DTM transactions and transform them to OCPI Sessions.<br>
              Currently you can import transactions with <v-chip size="small" variant="outlined">Start Method: TagAuth</v-chip> and <v-chip size="small" variant="outlined">Start Method: Remote</v-chip>  (CommandStart must exist in OCPI DB)<br>
              RealtimeAuth_Allowed will not be related to TagAuth sessions, for this to be possible we need to update the DTM code.<br>
            </p>
          <DTMSessionsImporter class="mt-4 mb-2"></DTMSessionsImporter>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title>
          <div class="d-flex align-center">
            <v-icon color="accent" size="small">mdi-key-wireless</v-icon>
            <h4 class="ml-2">Real Time RFID Authorization Test</h4>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pa-4">
            <p class="mb-6">
                Send a POST Token request to EMSP Providers to check if indicated token has permissions.
            </p>
            <RealTimeAuthTool></RealTimeAuthTool>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title>
          <div class="d-flex align-center">
            <v-icon color="accent" size="small">mdi-map-marker-remove</v-icon>
            <h4 class="ml-2">Delete Locations in Core</h4>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pa-4">
            <p class="mb-6">
                Delete all Locations, Chargepoints, Evses and Connectors in Core. This <b>CAN</b> (if selected) delete in OCPI.
            </p>
            <DeleteLocations></DeleteLocations>
            </v-expansion-panel-text>
      </v-expansion-panel>

    </v-expansion-panels>

  </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import ProvidersWithoutTariffs from "@/components/tools/ProvidersWithoutTariffs.vue"
import CollidedTariffs from "@/components/tools/CollidedTariffs.vue"
import ChargeSessionPriceCalculator from "@/components/tools/ChargeSessionPriceCalculator.vue"
import SessionsWithoutCdrs from "@/components/tools/SessionsWithoutCdrs.vue"
import EvseIdReferencesTool from "@/components/tools/EvseIdReferencesTool.vue"
import DTMSessionsImporter from "../../components/tools/DTMSessionsImporter.vue"
import RealTimeAuthTool from "../../components/tools/RealTimeAuthTool.vue"
import DeleteLocations from "@/components/tools/DeleteLocations.vue";

export default {
    components: {
    AppBar,
    ProvidersWithoutTariffs,
    CollidedTariffs,
    ChargeSessionPriceCalculator,
    SessionsWithoutCdrs,
    EvseIdReferencesTool,
    DTMSessionsImporter,
    RealTimeAuthTool,
    DeleteLocations
},
    data () {
      return {
        expanded: 0
      }
    }
}
</script>
