<template>
    <v-container fluid>

        <AppBar icon="mdi-map-marker" v-if="provider" :breadcrumbs="[
            { text: name, to: `/provider/${provider.id}` },
            { text: 'Locations', to: `/provider/${provider.id}/cpo/locations` },
            { text: locationName, icon: 'mdi-map-marker' }
        ]"/>

        <!-- Deleted at -->
        <v-alert type="error" v-if="location && location.deleted_at" class="justify-center mb-3" icon="false" variant="tonal" density="compact">
            Location deleted at <strong>{{ dateFullFormat(location.deleted_at) }}</strong>
        </v-alert>

        <v-toolbar v-if="location" color="transparent" class="mt-n2 mb-1">
            <v-toolbar-title>
                <LocationName
                    :location="{ name: location.name }"
                    extra="no-arrow" />
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="ml-2" variant="text" @click="dialogGroups = true"><v-icon start>mdi-folder-marker</v-icon>Groups</v-btn>
            <v-btn class="ml-2" variant="text" @click="showJSON('Location', location)" ><v-icon>mdi-code-json</v-icon></v-btn>
        </v-toolbar>

        <v-row>
            <v-col cols="12" md="8">
                <v-card :loading="loadingLocation" variant="outlined">
                    <v-row v-if="location">
                        
                        <v-col cols="12" md="2">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{location.id}}</v-list-item-title>
                                    <v-list-item-subtitle>Id.</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{location.backend_id}}</v-list-item-title>
                                    <v-list-item-subtitle>Core Id.</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{location.ocpi_id}}</v-list-item-title>
                                    <v-list-item-subtitle>OCPI Id.</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>

                        <v-col cols="12" md="2">
                            <v-list-item lines="two">
                                <v-list-item-title>{{ location.country }}</v-list-item-title>
                                <v-list-item-subtitle>Country</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-list-item lines="two">
                                <v-list-item-title>{{ location.publish }}</v-list-item-title>
                                <v-list-item-subtitle>Publish</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-list-item lines="two">
                                <v-list-item-title>{{ `${location.country_code}-${location.party_id}` }}</v-list-item-title>
                                <v-list-item-subtitle>Country-Party</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                        
                        <v-col cols="12" md="5" v-if="location.coordinates">
                            <v-list-item lines="two">
                                <v-list-item-title>
                                    <v-btn @click="openMaps(location.coordinates)" icon variant="text" density="compact">
                                        <v-icon color="accent">mdi-map</v-icon>
                                    </v-btn>
                                    {{location.coordinates.latitude}}, {{location.coordinates.longitude}}
                                </v-list-item-title>
                                <v-list-item-subtitle>Coordinates</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                        
                        
                    </v-row>

                    <v-divider class="mb-2" />

                    <h3 class="ml-4">Provider</h3>
                    <v-row v-if="provider">
                        <v-col cols="12" md="8">
                            <v-list-item lines="two">
                                <v-list-item-title>
                                    <ProviderName :provider="provider" />
                                </v-list-item-title>
                                <v-list-item-subtitle>Company</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-list-item lines="two">
                                <v-list-item-title>{{ provider.role }}</v-list-item-title>
                                <v-list-item-subtitle>Roles</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                    </v-row>

                </v-card>
            </v-col>
            <v-col cols="12" md="4" class="pl-0">
                <GoogleMapLoader v-if="mapConfig"
                    :map-config="mapConfig"
                    border-radius="5px"
                >
                    <template v-slot="{ google, map }">
                        <v-marker
                            :position="coordinates"
                            :google="google"
                            :map="map"
                        ></v-marker>
                    </template>
                </GoogleMapLoader>
            </v-col>
        </v-row>

        <v-row v-if="location" class="mt-0 pt-0">
            
            <v-col cols="12" md="4">
                <v-list-item lines="two">
                    <v-list-item-title>{{ dateFullFormat(location.created_at) }}</v-list-item-title>
                    <v-list-item-subtitle>Created at</v-list-item-subtitle>
                </v-list-item>
            </v-col>
            <v-col cols="12" md="4">
                <v-list-item lines="two">
                    <v-list-item-title>{{ dateFullFormat(location.updated_at) }}</v-list-item-title>
                    <v-list-item-subtitle>Updated at</v-list-item-subtitle>
                </v-list-item>
            </v-col>
            <v-col cols="12" md="4">
                <v-list-item lines="two">
                    <v-list-item-title>{{ dateFullFormat(location.last_updated) }}</v-list-item-title>
                    <v-list-item-subtitle>Last updated</v-list-item-subtitle>
                </v-list-item>
            </v-col>

        </v-row>
        
        <!-- Operaciones -->
        <h2 class="my-3 ml-2">
            <v-icon start size="x-small" color="accent">mdi-wrench</v-icon> 
            Operations
        </h2>
        <v-row>
            <v-col cols="12" sm="6" md="4" v-if="provider && provider.id == 1">
                <SendLocationTool class="fill-height" :locationId="location.id" :providers="externalProviders" :locationGroupProviders="locationGroupProviders"></SendLocationTool>
            </v-col>

            <v-col cols="12" sm="6" md="4" v-if="provider && provider.id == 1">
                <SendEvseStatusTool class="fill-height" :locationId="location.id" :locationGroupProviders="locationGroupProviders"></SendEvseStatusTool>
            </v-col>

            <v-col cols="12" sm="6" md="4" v-if="provider && provider.id == 1">
                <v-card variant="outlined" class="fill-height d-flex flex-column">
                    <v-card-text>
                        <p class="text-subtitle-2 mb-4"><v-icon start color="accent">mdi-import</v-icon>Import Location from DTM</p>
                        <p class="mb-4">Obtains Location from DTM and updates its data in OCPI. Includes location's chargepoints, evses, connectors and locations group relations.</p>
                    </v-card-text>
                    <v-card-actions class="mb-4">
                        <v-btn @click="importLocationFromDtm" variant="text" block :loading="loadingSendLocationToBackend" color="accent">
                            <v-icon start>mdi-upload</v-icon>
                            Import from DTM
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="12" sm="6" md="4" v-if="provider && provider.id != 1 && hasRole('CPO')">
                <v-card variant="outlined" class="fill-height d-flex flex-column">
                    <v-card-text>
                        <p class="text-subtitle-2 mb-4"><v-chip class="mr-2" color="amber accent-4" variant="outlined" size="small">GET</v-chip>Location</p>
                        <p>Update Location from Provider.</p>
                    </v-card-text>
                    <v-card-actions class="mb-4">
                        <v-btn @click="updateLocationFromProvider" variant="text" block :loading="loadingUpdateLocationFromProvider" color="accent">
                            <v-icon start>mdi-download</v-icon>
                            Update Location
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="12" sm="6" md="4" v-if="provider && provider.id != 1">
                <v-card variant="outlined" class="fill-height d-flex flex-column">
                    <v-card-text>
                        <p class="text-subtitle-2 mb-4"><v-icon start color="accent">mdi-sync</v-icon>Sync to Backend</p>
                        <p class="mb-4">Send Location to Backend.</p>
                    </v-card-text>
                    <v-card-actions class="mb-4">
                        <v-btn @click="sendLocationToBackend" variant="text" block :loading="loadingSendLocationToBackend" color="accent">
                            <v-icon start>mdi-send</v-icon>
                            Send to Backend
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- Soft Delete Location -->
            <v-col cols="12" sm="6" md="4" v-if="location && !location.deleted_at && provider.id !== 1">
                <v-card variant="outlined" class="fill-height d-flex flex-column">
                    <v-card-text class="pb-0">
                        <p class="text-subtitle-2 mb-4"><v-icon start color="accent">mdi-map-marker-remove</v-icon>Delete Location</p>
                        <p>Delete all Chargepoints, Evses and Connectors from this Location. This will <b>NOT</b> perform actions to Backend.</p>
                    </v-card-text>
                    <v-card-actions class="mb-4">
                        <v-btn @click="deleteLocation" variant="text" block :loading="loadingDeleteLocation" color="accent">
                            <v-icon start>mdi-delete-outline</v-icon>
                            Delete Location
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- Soft UnDelete Location -->
            <v-col cols="12" sm="6" md="4" v-if="location && location.deleted_at && provider.id !== 1">
                <v-card variant="outlined" class="fill-height d-flex flex-column">
                    <v-card-text class="pb-0">
                        <p class="text-subtitle-2 mb-4"><v-icon start color="accent">mdi-undo</v-icon>UnDelete Location</p>
                        <p>UnDelete all Chargepoints, Evses and Connectors from this Location where status is NOT 'REMOVED'. This will not perform actions to Backend.</p>                        
                    </v-card-text>
                    <v-card-actions class="mb-4">
                        <v-btn @click="unDeleteLocation" variant="text" block :loading="loadingUnDeleteLocation" color="accent">
                            <v-icon start>mdi-arrow-u-left-top</v-icon>
                            UnDelete Location
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- Compare External CPO Location with Wenea EMSP DB  -->
            <v-col cols="12" sm="6" md="4" v-if="provider && provider.id != 1 && hasRole('CPO')">
                <v-card variant="outlined" class="fill-height d-flex flex-column">
                    <v-card-text>
                        <p class="text-subtitle-2 mb-4"><v-icon left color="accent">mdi-compare</v-icon>Compare local DB/Provider Location data</p>
                        <p class="mb-4">Do a GET Location on CPO Provider and compare it with saved in DB.</p>
                    </v-card-text>
                    <v-card-actions class="mb-4">
                        <v-btn @click="compareLocation" variant="text" block :loading="loadingCompareLocation" color="accent">
                            <v-icon start>mdi-file-compare</v-icon>
                            Show comparsion
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- Compare Wenea CPO Location with External EMSP DB  -->
            <v-col cols="12" sm="6" md="4" v-if="provider && provider.id == 1">
                <CompareLocationToEMSP :locationId="location.id" :providers="externalProviders" :locationGroupProviders="locationGroupProviders" />
            </v-col>

        </v-row>

        <!-- Location Structure -->
        <v-row class="d-flex flex-row align-center">
            <h2 class="my-3 ml-2"><v-icon start  color="accent">mdi-file-tree-outline</v-icon> Structure</h2>
            <v-btn-toggle
                    v-model="locationStructureMode"
                    rounded="lg"
                    class="ml-4 align-center no-flex"
                    mandatory
                >
                    <v-btn value="simple" color="accent" height="30" class="toggle__opacity">
                        <span>Simple</span>
                    </v-btn>
                
                    <v-btn value="advanced" color="accent" height="30" class="toggle__opacity">
                        <span>Advanced</span>
                    </v-btn>
            </v-btn-toggle>
        </v-row>
        <LocationStructure :chargepoints="chargepoints" :mode="locationStructureMode"/>

        <v-dialog max-width="600" v-model="dialogGroups">
            <v-card title="Location groups">
                <v-divider />
                <v-card-text>

                    <v-table density="compact" hover>
                        <thead>
                            <tr>
                                <th></th>
                                <th class="text-left">Name</th>
                                <th class="text-left">Reference</th>
                                <th class="text-left">Id</th>
                                <th class="text-left">Core Id</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="group in location.locationGroups"
                            :key="group.reference"
                        >
                            <td><v-btn icon="mdi-arrow-top-right" variant="plain" :to="`/location-group/${group.id}`" density="compact"></v-btn></td>
                            <td>{{ group.name }}</td>
                            <td>{{ group.reference }}</td>
                            <td>{{ group.id }}</td>
                            <td>{{ group.backend_id }}</td>
                        </tr>
                        </tbody>
                    </v-table>

                </v-card-text>
            </v-card>
        </v-dialog>

        <JsonDialog />

    </v-container>
</template>

<script>
import { EventBus } from "@/event-bus"
import AppBar from "@/components/AppBar"
import GoogleMapLoader from '@/components/map/GoogleMapLoader'
import VMarker from '@/components/map/VMarker'
import JsonDialog from "@/components/JsonDialog"
import ProviderName from "@/components/provider/ProviderName"
import LocationName from "@/components/location/LocationName"
import ChargepointName from "@/components/chargepoint/ChargepointName"
import LocationStructure from "@/components/location/LocationStructure"
import SendLocationTool from "@/components/location/SendLocationTool"
import CompareLocationToEMSP from "@/components/location/CompareLocationToEMSP.vue"
import SendEvseStatusTool from "@/components/location/SendEvseStatusTool"

export default {
    name: "providers",
    components: {
        AppBar,
        GoogleMapLoader,
        VMarker,
        JsonDialog,
        ProviderName,
        LocationName,
        ChargepointName,
        LocationStructure,
        SendLocationTool,
        CompareLocationToEMSP,
        SendEvseStatusTool
    },
    inject: ['$api'],
    data() {
        return {
            loadingLocation: false,
            location: null,
            chargepoints: [],
            provider: null,
            externalProviders: [],
            locationGroupProviders: [],
            providerToSend: null,
            loadingImportLocationFromBackend: false,
            loadingSendLocationToBackend: false,
            loadingDeleteLocation: false,
            loadingUnDeleteLocation: false,
            loadingUpdateLocationFromProvider: false,
            loadingCompareLocation: false,
            toBackend: null,
            coordinates: null,
            mapConfig: null,
            reportsChannel: null,
            locationStructureMode: 'simple',
            dialogGroups: false
        }
    },
    computed: {
        locationName: function(){
            return (this.location) ? this.location.name : "-"
        },
        providerInfo: function(){
            return (this.provider) ? { name: `${this.provider.country_code}-${this.provider.party_id} (${this.provider.role})`, id: this.provider.id } : { name: '-', id: null }
        },
        name(){
           return this.provider.user?.company?.name || this.provider.user.username
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load(){
            
            let id = this.$route.params.id
            if( !id ){ this.showError('No hay Id.!'); return; }
            
            this.loadingLocation = true
            this.$api.get(`location/${id}`)
                .then(resp => {
                    this.location = resp.data
                    this.provider = resp.data.provider
                    this.chargepoints = resp.data.chargepoints
                    this.loadMap(this.location)
                    this.getLocationGroupProviders()
                    this.getConnectorsApplicableTariffsIds()
                    this.getExternalProviders()
                })
                .catch(this.showError)
                .then(_ => { this.loadingLocation = false })
        },
        loadMap(location) {
            this.coordinates = { 
                lat: Number(location.coordinates?.latitude), 
                lng: Number(location.coordinates?.longitude) 
            }

            this.mapConfig = {
                center: this.coordinates,
                zoom: 16,
                minZoom: 4,
            }
        },
        getLocationGroupProviders() {
            let id = this.$route.params.id

            this.$api.get(`location/${id}/location-groups/providers`)
                .then(resp => this.locationGroupProviders = resp.data.filter(p => p.role.includes('EMSP') || p.role.includes('NSP')))
        },
        getExternalProviders(){
            this.$api.get("/providers-external")
                .then(res => {
                    this.externalProviders = res.data.filter(p => p.role.includes('EMSP') || p.role.includes('NSP'))

                    this.locationGroupProviders.forEach(lgp => {
                        let provider = this.externalProviders.find(ep => ep.id == lgp.id)
                        if( provider ){
                            lgp.company = provider.company
                            lgp.user = provider.user
                        }
                    })
                })
                .catch(this.showError)
        },
        getConnectorsApplicableTariffsIds(){
            if( this.provider.id == 1 ){ 
                const connector_ids = this.chargepoints.flatMap(cp => cp.evses.flatMap(evse => evse.connectors.map(connector => connector.id)))
                if( !connector_ids.length ) return 

                this.$api.get(`tariffs/connectors/${connector_ids.join(',')}`)
                .then(resp => {
                    this.chargepoints.forEach(cp => {
                        cp.evses.forEach(evse => {
                            evse.connectors.forEach(connector => {
                                let applicable_tariffs = resp.data.filter(tariff => tariff.connector_id == connector.id)
                                connector.applicable_tariffs = applicable_tariffs
                            })
                        })
                    })
                })
                .catch(this.showError)

            }else{
                const tariff_ids = this.chargepoints.flatMap(cp => cp.evses.flatMap(evse => evse.connectors.map(connector => connector.tariff_ids)))
                if( !tariff_ids.length ) return 

                this.$api.get(`tariffs/${tariff_ids.join(',')}/provider/${this.provider.id}`)
                .then(resp => {
                    this.chargepoints.forEach(cp => {
                        cp.evses.forEach(evse => {
                            evse.connectors.forEach(connector => {
                                let applicable_tariffs = resp.data.filter(tariff => connector.tariff_ids.includes(tariff.ocpi_id))
                                connector.applicable_tariffs = applicable_tariffs
                            })
                        })
                    })
                })
                .catch(this.showError)
            }

        },
        sendLocationToProvider(){
            if( !this.providerToSend ){ this.showError('Provider must be selected to send Location'); return; }

            this.loadingSendLocation = true
            this.$api.get(`location/${this.location.id}/send-to/provider/${this.providerToSend}`)
                .then(() => this.showSuccess('Data sent successfully'))
                .catch(this.showError)
                .then(() => { this.loadingSendLocation = false })
        },
        importLocationFromDtm(){
            this.loadingImportLocationFromBackend = true

            let workerUrl = `location/${this.location.backend_id}/update-from-dtm`

            this.$api.get(workerUrl)
                .then(resp => {
                    this.showSuccess(resp.data)
                    this.load()
                })
                .catch(this.showError)
                .then(() => { this.loadingImportLocationFromBackend = false })
        },
        sendLocationToBackend(){
            this.loadingSendLocationToBackend = true
            let workerUrl = `location/${this.location.id}/send-location-to-backend`

            this.$api.get(workerUrl)
                .then(resp => {
                    this.showSuccess('Data sent successfully')
                })
                .catch(this.showError)
                .then(_ => { this.loadingSendLocationToBackend = false })
        },
        deleteLocation(){
            const confirmed = confirm('Are you sure you want to mark this Location as DELETED?')
            if(!confirmed){ return }

            this.loadingDeleteLocation = true
            this.$api.get(`location/${this.location.id}/delete-location`)
                .then(resp => {
                    this.showSuccess(resp.data)
                })
                .catch(this.showError)
                .then(_ => { this.loadingDeleteLocation = false })
        },
        unDeleteLocation(){
            const confirmed = confirm('Are you sure you want to mark this Location as UNDELETED?')
            if(!confirmed){ return }

            this.loadingUnDeleteLocation = true
            this.$api.get(`location/${this.location.id}/un-delete-location`)
                .then(resp => {
                    this.showSuccess(resp.data)
                })
                .catch(this.showError)
                .then(_ => { this.loadingUnDeleteLocation = false })
        },
        updateLocationFromProvider() {
            if (!this.provider.id) { return }

            this.loadingUpdateLocationFromProvider = true
            
            this.$api
                .get(`locations/${this.location.ocpi_id}/fetch`)
                .catch(this.showError)
                .then((resp)=>{
                    if (!resp?.data?.success) {
                        this.showError(resp?.data?.errorMessage)
                    }else{
                        this.showSuccess('Location updated successfully')
                        this.load()
                    }
                })
            this.loadingUpdateLocationFromProvider = false
        },
        hasRole(role){
            return this.provider.role && this.provider.role.includes(role)
        },
        async compareLocation(){
            this.loadingCompareLocation = true
            const jsons = await this.$api.get(`locations/${this.location.id}/local-and-provider-data`)
                .then(resp => resp.data)
                .catch(this.showError)

                this.loadingCompareLocation = false

            EventBus().emit(
                "dialog-json:open",
                { 
                    title: 'Location Comparison Tool',
                    jsonTitle: 'Wenea db',
                    json2Title: `Provider ${this.providerInfo.name} as CPO`,
                    json: jsons.local_db_location, 
                    json2: jsons.provider_location 
                }
            )

            
        },
        showJSON(title, data, expand = 1) {
            EventBus().emit(
                "dialog-json:open",
                { title, json: data, expand, maxWidth: 600 }
            )
        },

    }
}
</script>

<style scoped>
    :deep(.jv-container).jv-code{
        padding: 0px;
    }

    :deep(.fill-height){
        margin-bottom: 24px;
    }

    :deep(.fill-height).v-card__actions{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 10px;
        padding-inline: 14px;
    }

    .toggle__opacity .v-btn__overlay{
        opacity: 0.5;
    }

</style>