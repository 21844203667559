<template>
    <v-dialog v-model="show" persistent max-width="1180px">
        <v-card v-if="user">
            <v-card-title class="bg-accent">
               Get provider credentials
            </v-card-title>

            <v-stepper v-model="step">
                <v-stepper-header>
                    <v-divider></v-divider>
                    <v-stepper-item value="1" :complete="step > 1" title="OCPI Version"/>
                    <v-divider></v-divider>
                    <v-stepper-item value="2" :complete="step > 2" title="Get Credentials"/>
                    <v-divider></v-divider>
                </v-stepper-header>

                <v-stepper-window>
                    <!-- Step 1. Fetch versions -->
                    <v-stepper-window-item value="1">
                        <div>
                            <v-btn 
                                color="accent"
                                @click="fetchVersions()"
                            >
                                Get provider versions
                            </v-btn>
                        </div>
                        <div v-if="versions">
                            <div class="mt-4">
                                <h3>What version of OCPI do you want to use?</h3>
                            </div>
                            <div v-if="!versionSelected">
                                <v-data-table
                                    :headers="[
                                        {title: 'Version', key: 'version'},
                                        {title: 'URL', key: 'url'}
                                    ]"
                                    :items="versions"
                                    @click:row="selectVersion"
                                    :disable-pagination="true"
                                    :hide-default-footer="true"
                                    disable-sort
                                    items-per-page="-1"
                                    class="elevation-1 pointer-on-hover"
                                >
                                </v-data-table>
                                <div class="text-right body-2 mt-1">* Choose one by clicking on row</div>
                            </div>
                            <div v-if="versionSelected">
                                <v-chip color="accent" size="large">OCPI v.{{versionSelected.version}}</v-chip>
                            </div>
                        </div>
                        <div v-if="versionSelected && !endpoints" class="mt-4">
                            <v-btn 
                                color="primary" 
                                @click="fetchEndpoints"
                                prepend-icon="mdi-arrow-down"
                            >
                                Get version endpoints
                            </v-btn>
                        </div>
                        <div v-if="endpoints">
                            <div class="mt-8 d-flex justify-space-between">
                                <h3>Features available from provider</h3>
                            </div>
                            <v-data-table
                                :headers="[
                                    {title: 'Module', key: 'identifier'},
                                    {title: 'Role', key: 'role'},
                                    {title: 'URL', key: 'url'}
                                ]"
                                :items="endpoints"
                                :disable-pagination="true"
                                :hide-default-footer="true"
                                items-per-page="-1"
                                disable-sort
                            >
                            </v-data-table>
                        </div>
                      
                        <v-divider class="my-4"></v-divider>
                        <v-row class="ma-0 pb-1">
                            <v-btn 
                                @click="step = 1"
                                :disabled="!endpoints"
                                color="secondary"
                                variant="flat"
                                append-icon="mdi-arrow-right-bold"
                            >
                                Continue
                            </v-btn>
                            <v-spacer />
                            <v-btn variant="text" @click="show = false">Cancel</v-btn>
                        </v-row>
                    </v-stepper-window-item>

                    <!-- Step 2. Sincronizar credenciales -->
                    <v-stepper-window-item value="2">
                        <div>
                            <h3>Synchronize credentials</h3>
                            <v-btn color="primary" class="mr-2" v-if="!sendCredentialsResponse" @click="sendCredentials('create')">Create</v-btn>
                            <v-btn color="primary" v-if="!sendCredentialsResponse" @click="sendCredentials('update')">Update</v-btn>
                            <div v-if="sendCredentialsResponse">
                                <h4>Synchronization completed</h4>
                                <div>Token C: {{sendCredentialsResponse.token}}</div>
                                <div>URL: {{sendCredentialsResponse.url_versions}}</div>
                                <div>Roles: {{sendCredentialsResponse.roles}}</div>
                            </div>
                        </div>

                        <v-divider class="my-4"></v-divider>
                        <v-row class="ma-0  pb-1">
                            <v-btn color="secondary" @click="show = false" :disabled="!sendCredentialsResponse">Close window <v-icon right>mdi-arrow-right-bold</v-icon></v-btn>
                            <v-spacer />
                            <v-btn variant="text" @click="show = false">Cancel</v-btn>
                        </v-row>
                    </v-stepper-window-item>
                </v-stepper-window>
            </v-stepper>

        </v-card>
    </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus"

export default {
    data() {
        return {
            show: false,
            step: 0,
            user: null,

            versions: null,
            versionSelected: null,
            endpoints: null,
            sendCredentialsResponse: null
            
        }
    },
    inject: ['$api'],
    watch: {
        show( visible ) {
            if ( !visible ) {
                this.clearDialog()
                EventBus().emit("dialog-user-fetch-credentials:close")
            }
        },
        versionSelected(newValue){
            if(newValue?.url) {
                this.$api.patch(`/user/${this.user.id}`, { ocpi_url_endpoints: newValue.url })
            }
        }
    },
    created() {
        var vm = this
        EventBus().on("dialog-provider-fetch-credentials:open", function( user ) {
            if( !user ){ vm.showError("Data error, no User"); return false }
            vm.user = user
            vm.step = 0
            vm.show = true
        })
    },
    methods: {
        fetchVersions(){

            if( !this.user.ocpi_url_versions || (!this.user.ocpi_token_a && !this.user.ocpi_token_c) ){
                this.showError('Wrong url, token A or token C')
                return;
            }

            this.versionSelected = null
            this.endpoints = null
            this.$api.get(`/user/${this.user.id}/fetch-versions`)
                .then(resp => {
                    this.versions = resp.data
                })
                .catch(this.showError)

        },
        fetchEndpoints(){

            if( !this.versionSelected ){
                this.showError('You must select a version of OCPI')
                return;
            }

            this.$api.get(`/user/${this.user.id}/fetch-url-endpoints?url=${this.versionSelected.url}`)
                .then(resp => {
                    this.endpoints = resp.data
                })
                .catch(this.showError)
        },
        sendCredentials(cmd){

            this.$api.get(`/user/${this.user.id}/credentials/${cmd}`)
                .then(resp => {
                    this.sendCredentialsResponse = resp.data
                })
                .catch(this.showError)

        },
        selectVersion( event, { item } ){
            this.versionSelected = item
        },
        getHeaders( token ){
            let t = ( !token ) ? this.user.ocpi_token_a : token
            return {headers: {'Authorization': `Token ${t}`, 'Content-Type': 'application/json'}}
        },
        clearDialog(){
            this.versions = null
            this.versionSelected = null
            this.endpoints = null
            this.sendCredentialsResponse = null
            this.user = null
            this.step = 0
        }
    }
}
</script>

<style scoped>
    .v-stepper {
        overflow-y: auto;
    }
</style>