<template>
    <v-dialog v-model="show" persistent max-width="1180px">
        <v-card>

            <v-card-title class="pa-0">
                <v-toolbar color="accent white--text elevation-0">
                    <v-icon class="ml-6" color="white" size="small">mdi-list-box-outline</v-icon> 
                    <v-toolbar-title class="ml-2">Results</v-toolbar-title>
                </v-toolbar>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="pb-0">
                <div v-if="loading" class="pb-2">
                    <v-btn  style="position: absolute; right: 24px; top: 58px"
                        @click="() => { $emit('force-stop'); stopping = true }"
                        variant="tonal"
                        color="error"
                    >   
                        Force STOP
                    </v-btn>
                    <v-row class="ma-0 mb-2" align="center" justify="center">
                        <v-progress-circular
                            class="mr-2"
                            :size="16"
                            width="2"
                            indeterminate
                            color="accent"
                        />
                        <template v-if="stopping && loading">
                            Stopping
                        </template>
                        <template v-else>
                            Sent {{ sent }} of {{ total ? total : '?' }}
                        </template>
                    </v-row>
                </div>

                <v-card variant="outlined">
                    <v-data-table
                        class="results-data-table" 
                        :items="results"              
                        :headers="[
                            { title: 'Item', key: 'item' },
                            { title: 'Success', key: 'success' },
                            { title: 'Message', key: 'message' }
                        ]"
                        density="compact"
                        :loading="loading"
                        :sort-by="[{ key: 'sent_at', order: 'desc' }]" 
                    >
                        <template v-slot:loader>
                            <v-progress-linear v-model="progress" color="accent"></v-progress-linear>
                        </template>
                        <template v-slot:item.success="{ item }">
                            <v-icon v-if="item.success" color="success">mdi-check-circle</v-icon>
                            <v-icon v-else color="error">mdi-close-circle</v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-card-text>

            <v-card-actions class="pa-5 pt-0" style="min-height: 0;">
                <v-btn class="mt-5"
                    v-if="!loading" 
                    @click="show = false" 
                    variant="text"
                >
                    Close
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus"

export default {
    name: "results-dialog",
    data() {
        return {
            show: false,
            loading: false,
            stopping: false,
            results: [],
            progress: 0,
            total: 0,
            sent: 0
        }
    },
    watch: {
        show(show) {
            if (!show) {
                EventBus().emit("results-dialog:close")
            }
        }
    },
    created() {
        var vm = this

        EventBus().on("results-dialog:message", function(data) {
            console.log(data)
            vm.show = true

            vm.loading = !data.finished
            vm.results = data.results
            vm.total = data.total
            vm.progress = data.progress
            vm.sent = data.sent
        })

    }
};
</script>