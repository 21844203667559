<template>
    <v-card variant="outlined" class="fill-height d-flex flex-column">
        <v-card-text>
            <p class="text-subtitle-2 mb-4"><v-icon right color="accent">mdi-compare</v-icon> Compare Wenea Location / Provider data</p>
            <p class="mb-4">GET Wenea Location on EMSP Provider and compare it with our saved data in DB.</p>
            <v-autocomplete 
                class="mb-0" 
                v-model="providerToSend"
                :items="showAllProviders ? providers : locationGroupProviders" 
                :rules="[v => !!v]" 
                item-text="id"
                item-value="id"
                :custom-filter="autocompleteDeepSearch"
                label="Provider to compare"
                variant="outlined" 
                hide-details
            >
                <template v-slot:selection="{ props, item }">
                    <v-chip v-bind="props" size="small" variant="outlined">
                        <v-icon color="black">mdi-earth</v-icon>{{ `${item.raw.country_code}-${item.raw.party_id}` }}
                    </v-chip>
                </template>
                <template v-slot:prepend-item>
                    <v-switch 
                        v-model="showAllProviders"
                        class="pl-4" 
                        color="accent" 
                        hide-details
                        label="Providers without location group permissions"
                    />
                </template>

                <template v-slot:item="{ props, item }">

                    <v-list-item v-bind="props" title>
                        <v-list-item-title>
                            <ProviderSelectorTemplate :provider="item.raw" />
                        </v-list-item-title>
                        
                    </v-list-item>
                </template>
            </v-autocomplete>
        </v-card-text>
        <v-card-actions class="mb-4">
            <v-btn @click="compareLocationToEMSP" variant="text" block :loading="loadingCompareLocationToEMSP" color="accent">
                <v-icon start>mdi-file-compare</v-icon>
                Show comparsion
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { EventBus } from "@/event-bus"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"

export default {
    name: "compare-location-to-emsp",
    components: {
        ProviderSelectorTemplate
    },
    props: {
        locationId: {
            type: Number,
            required: true
        },
        locationGroupProviders: {
            type: Array,
            required: true
        },
        providers: {
            type: Array,
            default: []
        }
    },
    inject: ['$api'],
    data() {
        return {
            providerToSend: null,
            showAllProviders: false,
            loadingCompareLocationToEMSP: false
        }
    },
    watch:{
        showAllProviders(show) {
            if (!show) { this.providerToSend = null }
        }
    },
    methods: {
        async compareLocationToEMSP(){

            if (!this.providerToSend) { 
                this.showError('Provider must be selected to send Location') 
                return 
            }

            this.loadingCompareLocationToEMSP = true
            const jsons = await this.$api.get(`locations/${this.locationId}/local-and-provider-data?provider_id=${this.providerToSend}`)
                .then(resp => resp.data)
                .catch(this.showError)

                this.loadingCompareLocationToEMSP = false

            const providerObject = this.providers.find(p => p.id === this.providerToSend)

            EventBus().emit(
                "dialog-json:open",
                { 
                    title: 'Location Comparison Tool',
                    jsonTitle: 'Wenea db',
                    json2Title: `Provider ${providerObject.country_code}-${providerObject.party_id} (${providerObject.role}) as EMSP`,
                    json: jsons.local_db_location, 
                    json2: jsons.provider_location 
                }
            )
        }
    }
}
</script>

<style scoped>
    /* Fix vuetify visual bug */
    :deep(.v-list-item--highlighted::before){
        opacity: 0 !important;
    }
    /* Fix vuetify visual bug */
    :deep(.v-list-item--highlighted.v-list-item--active::before){
        opacity: 0.12 !important;
    }
</style>