<template>
    <v-container fluid>

        <AppBar title="Wenea Tokens" icon="mdi-key" />

        <v-row>
            <v-col cols="6">
                <v-card height="100%" class="fill-height">
                    <v-card-title class="mb-3">
                        <v-icon class="mr-1" icon="mdi-send" color="accent"></v-icon>
                        Send token to all CPOs
                    </v-card-title>
        
                    <v-card-text>
                        <v-alert class="mb-4" type="info" variant="tonal" height="100%">
                            <p>This tool sends one of tokens stored in Redis key
                            <v-chip variant="outlined" size="small">token-contract-ids:{contract_id}:{rfid}</v-chip> 
                            to all CPOs. In case of HUB it only sends it to main connection. 
                            Tokens of these keys are imported into the 'Token Contract IDs' CronJob.</p>
                        </v-alert>

                        <SearchTextField 
                            v-model="selectedTokenSearch"
                        />

                        <v-card variant="outlined" class="mt-4">
                            <v-data-table
                                v-model="selectedToken"
                                :items="weneaTokens"
                                :headers="[
                                    { title: 'Uid', key: 'uid' },
                                    { title: 'Contract Id.', key: 'contract_id' },
                                    { title: 'Valid', key: 'valid' },
                                    { title: 'Updated at', key: 'last_updated' },
                                    { title: 'Created at', key: 'created_at' },
                                ]"
                                :search="selectedTokenSearch"
                                show-select
                                select-strategy="single"
                                return-object
                                density="compact"
                                class="accent-table rounded-table"
                            >
                                <template v-slot:item.valid="{ item }">
                                    <v-icon v-if="item.valid" color="success">mdi-check-circle</v-icon>
                                    <v-icon v-else color="error">mdi-close-circle</v-icon>
                                </template>
                                <template v-slot:item.last_updated="{ item }">
                                    {{ dateFullFormat(item.last_updated) }}
                                </template>
                                <template v-slot:item.created_at="{ item }">
                                    {{ dateFullFormat(item.created_at   ) }}
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn 
                            color="accent"
                            variant="tonal"
                            @click="sendWeneaToken"
                        >
                            Send
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="6">
                <v-card height="100%" class="fill-height">
                    <v-card-title class="mb-3">
                        <v-icon class="mr-1" icon="mdi-send" color="accent"></v-icon>
                        Send all tokens to CPO
                    </v-card-title>
        
                    <v-card-text>
                        <v-alert class="mb-4" type="info" variant="tonal">
                            <p>This tool sends all the tokens stored in Redis key 
                            <v-chip variant="outlined" size="small">token-contract-ids:{contract_id}:{rfid}</v-chip> to a provider. 
                            Tokens of these keys are imported into the 
                            'Token Contract IDs' CronJob.</p>
                        </v-alert>

                        <SearchTextField 
                            v-model="selectedProviderSearch"
                        />

                        <v-card variant="outlined" class="mt-4">
                            <v-data-table 
                                class="accent-table rounded-table"
                                v-model="selectedProvider"
                                :items="providers"
                                :headers="[
                                    { title: 'Id.', key: 'id' },
                                    { title: 'Country code', key: 'country_code' },
                                    { title: 'Party Id.', key: 'party_id' },
                                    
                                ]"
                                :search="selectedProviderSearch"
                                show-select
                                select-strategy="single"
                                :sort-by="[{ key: 'id', order: 'asc' }]"      
                                density="compact"
                            >
                            </v-data-table>
                        </v-card>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn 
                            color="accent"
                            variant="tonal"
                            @click="sendWeneaTokensToProvider"
                        >
                            Send
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <ResultsDialog @force-stop="forceStop"/>
    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import ResultsDialog from "@/components/wenea-tokens/ResultsDialog"
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import Bws from "@/plugins/BackofficeWebsocket.js"
import { EventBus } from "@/event-bus"

export default {
    name: "wenea",
    components: {
        AppBar,
        SearchTextField,
        ResultsDialog
    },
    inject: ['$api'],
    data() {
        return {
            expanded: 0,
            selectedProviderSearch: '',
            providers: [],
            selectedProvider: null,
            selectedTokenSearch: '',
            weneaTokens: [],
            selectedToken: null
        }
    },
    mounted(){
         
        this.wsChannel = Bws.getSubscription('wenea-tokens:results')

        this.wsChannel?.on("message", (data) => {
            EventBus().emit("results-dialog:message", data)
        })

        this.wsChannel?.on("error", (error) => {
            this.showError(error)
        }) 

        this.$api.get("/providers")
                .then(res => {
                    this.providers = res.data
                        .filter(p => p.id != 1 && p.role.includes('CPO'))
                        .map(p => { 
                            p.id === this.wenea_info ? this.selectedProvider = p.id : ''
                            return { 
                                ...p, 
                                title: p.role ? `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username}`, 
                                value: p.id 
                            } 
                        })
                })
                .catch(this.showError)

        this.$api.get('wenea-tokens')
            .then(resp => this.weneaTokens = resp.data)
            .catch(this.showError)
    },
    methods: {
        sendWeneaToken(){
            if ( !this.selectedToken ) { 
                return this.showError('A token must be selected')
            }

            this.wsChannel.emit('send-token', { token: this.selectedToken[0] })
        },
        sendWeneaTokensToProvider(){
            if ( !this.selectedProvider ) { 
                return this.showError('A provider must be selected')
            }

            this.wsChannel.emit('send-tokens-to-provider', { provider_id: this.selectedProvider[0] })
        },
        forceStop() {
            this.wsChannel.emit('force-stop')
        }
    }
};
</script>

<style scoped>
    .fill-height {
        margin-bottom: 38px;
    }

    .fill-height .v-card-actions {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 10px;
        padding-inline: 14px;
    }
</style>
