<template>
  <div>
   
    <v-form 
        ref="form"
        v-model="valid"
    >
        <v-row>
            <v-col cols="12" >
                <v-textarea
                    v-model="selectedLocationIds"
                    label="Location IDs separated by comma"
                    color="accent"
                    clearable
                    variant="outlined"
                    hint="Comma separated"
                    :rules="[rules.required]"
                ></v-textarea>
            </v-col>
        </v-row>

        <v-row class="mt-1">
            <v-col cols="12" lg="2" xl="1">
                <BooleanSelector
                    class="filter-field accent"
                    v-model="selectedDeleteFromOCPI"
                    label="Delete in OCPI"
                    trueLabel="Yes"
                    falseLabel="No"
                />
            </v-col>

            <v-col cols="12" lg="2" xl="1">
                <v-text-field 
                class="filter-field"
                v-model="delayBetweenRequests"
                :rules="[rules.isANumber]"
                label="Time between requests"
                color="accent"
                variant="outlined"
                >
                <template v-slot:append-inner>s</template>
                </v-text-field>
            </v-col>

            <v-col cols="12" lg="2" xl="1">
                <v-btn 
                    :loading="loading"
                    :disabled="!valid"
                    block
                    variant="outlined"
                    color="error"
                    @click="dialogDelete = true"
                >
                    Delete<v-icon end>mdi-delete</v-icon>
                </v-btn>
            </v-col>

            <v-col cols="12" lg="2" xl="1" v-if="locationDeleteCollectionReport">
                <v-btn 
                    variant="text" @click="showReport = !showReport" block
                >
                    Last report <v-icon end>{{ showReport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-form>

    <v-row>
        <transition name="slide-y-transition">
        <div v-if="showReport" class="ml-4 mb-2" style="">
            <v-btn variant="text" icon @click="showDeletedReportDialog"><v-icon>mdi-magnify</v-icon></v-btn>
            <v-chip class="ml-2" variant="outlined" color="blue">Deleted {{ locationDeleteCollectionReport.deleted }}</v-chip>
            <v-chip class="ml-2" variant="outlined" color="red">Errors {{ locationDeleteCollectionReport.errors_list.length }}</v-chip>
            <v-chip class="ml-2" variant="outlined">
            <v-icon start color="grey">mdi-calendar</v-icon> 
            {{ dateFullFormat(locationDeleteCollectionReport.created_at) }}
            </v-chip>
        </div>
        </transition>
    </v-row>

    <JsonDialog />

    <v-dialog v-model="dialogDelete" max-width="550px">
        <v-card>
            <v-card-title style="white-space: normal;">Are you sure you want to proceed with the DELETION?</v-card-title>
            <v-card-text>
                <p class="mb-2">This action will send delete to Core for Locations, Chargepoints, Evses and Connectors.</p>
                <ul>
                    <li>It <b>WILL</b> delete <b>ONLY</b> APP/Core published.</li>

                    <li v-if="selectedDeleteFromOCPI">It <b>WILL</b> delete in OCPI.</li>
                    <li v-else>It <b>WILL NOT</b> delete in OCPI.</li>

                    <li v-if="delayBetweenRequests">It will wait {{ delayBetweenRequests }} seconds between requests.</li>
                </ul>
            </v-card-text>
            <v-card-actions>
                <v-btn class="mt-5" @click="closeDelete">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="mt-5" color="error" @click="deleteLocations">DELETE</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

  </div>
</template>
<script>
import { EventBus } from "@/event-bus"
import BooleanSelector from "@/components/ui-components/BooleanSelector.vue"
import JsonDialog from "@/components/JsonDialog"

export default {
  name: "delete-locations-from-provider",
  props: ["providerId"],
  components: {
    BooleanSelector,
    JsonDialog
  },
  inject: ['$api'],
  data() {
    return {
        valid: false,
        loading: false,
        dialogDelete: false,
        selectedLocationIds: [],
        selectedDeleteFromOCPI: false,
        delayBetweenRequests: 0.2,
        showReport: false,
        locationDeleteCollectionReport: null,
        rules: {
          isANumber: v => !isNaN(v) || 'Must be a number',
          required: v => !!v || 'This field is required',
        }
    }
  },
  mounted() {
    this.getLastlocationDeleteCollectionReport()
  },
  methods: {
    getLastlocationDeleteCollectionReport(){
      this.$api.get(`/reports/location_delete_collection`)
        .then(res => {
          this.locationDeleteCollectionReport = res.data
          this.loading = false
        })
        .catch(this.showError)
    },
    deleteLocations() {
        
        let queryParams = {
            location_ids: this.selectedLocationIds,
            delete_from_ocpi: this.selectedDeleteFromOCPI,
            delay_between_requests: this.delayBetweenRequests || 0
        }

        this.loading = true

        this.$api.delete(`/core-locations`, { params: queryParams })
          .then(() => {
            this.showSuccess('Locations deleted')
            this.getLastlocationDeleteCollectionReport()
          })
          .catch(this.showError)
          .finally(() => {
            this.loading = false
          })

        this.closeDelete()
    },
    showDeletedReportDialog(){
      EventBus().emit(
          "dialog-json:open",
          { title: 'Location delete collection report', json: this.locationDeleteCollectionReport }
      )
    },
    closeDelete () {
        this.dialogDelete = false
    }
  }
};
</script>