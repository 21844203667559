<template>
    <v-app>

        <NavigationDrawer
            :structure="navigationStructure"
        >
            <template v-slot:prepend>
                <v-list-item>
                    <v-row no-gutters class="align-center my-2">
                        <v-col cols="auto" class="d-flex">
                            <v-img src="@/assets/wenea-ico.png" height="54" width="54" contain></v-img>    
                        </v-col>
                        <v-col>
                            <v-list-item-title class="text-center">
                                <v-img src="@/assets/wenea-text-black.png" height="26" contain></v-img>
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-center mt-1">
                                <strong>OCPI</strong> Backoffice
                            </v-list-item-subtitle>  
                        </v-col>
                    </v-row>
                </v-list-item>

                <v-list-item>
                    <template v-slot:prepend>
                        <v-icon>mdi-account</v-icon>
                    </template>
        
                    <div>
                        <span v-if="user">{{user.username}}</span>
                    </div>
                    <template v-slot:append>
                        <v-btn icon="mdi-power" @click="logout" variant="text" class="float-right"/>
                    </template>
                </v-list-item>
                
                <v-divider></v-divider>
            </template>

            <template v-slot:item.loc-groups.append>
                <v-divider />
            </template>
            <template v-slot:item.operations.append>
                <v-divider />
            </template>
            <template v-slot:item.session-errors.append>
                <v-divider />
            </template>
            <template v-slot:item.wenea.icon>
                <WeneaIcon height="24px" color="black" class="mr-8"/>
            </template>

            <template v-slot:append>
                <div class="pa-1" v-if="projectInfo">
                    <v-btn variant="text" size="x-small" :href="`https://github.com/wenea/ocpi/releases/tag/${version}`" target="_blank" class="grey--text">Ver. {{ version }}</v-btn>
                </div>
            </template>
        </NavigationDrawer>

        <v-main>
            <router-view fluid />
        </v-main>

        <div style="position: fixed; bottom: 0; width: 100%; z-index: 9999;">
            <v-alert
                class="ma-3 app-alert"
                v-model="$store.state.alert.show"
                :type="$store.state.alert.type"
                :icon="$store.state.alert.icon"
                :timeout="$store.state.alert.timeout"
                transition="slide-y-reverse-transition"
                closable
                close-icon="mdi-close-thick"
            >
                {{ $store.state.alert.text }}
                <template v-if="$store.state.alert.button">
                    <v-btn v-if="$store.state.alert.button.to" 
                        :to="$store.state.alert.button.to"
                        size="small" 
                        variant="text" 
                    >
                        {{ $store.state.alert.button.text }}
                        <v-icon v-if="$store.state.alert.button.icon" class="pl-2">{{ $store.state.alert.button.icon }}</v-icon>
                    </v-btn>
                    <v-btn v-else-if="$store.state.alert.button.clickFn" 
                        @click="$store.state.alert.button.clickFn"
                        size="small" variant="text" 
                    >
                        {{ $store.state.alert.button.text }}
                        <v-icon v-if="$store.state.alert.button.icon" class="pl-2">{{ $store.state.alert.button.icon }}</v-icon>
                    </v-btn>
                    <v-btn v-else size="small" variant="text">
                        {{ $store.state.alert.button.text }}
                        <v-icon v-if="$store.state.alert.button.icon" class="pl-2">{{ $store.state.alert.button.icon }}</v-icon>
                    </v-btn>

                </template>
            </v-alert>
        </div>

        <v-snackbar v-model="$store.state.snackbar.show" :color="$store.state.snackbar.color">
            {{ $store.state.snackbar.text }}
            <v-btn text @click="$store.dispatch('snackbar_hide')">Close</v-btn>
        </v-snackbar>

    </v-app>
</template>

<script>
import NavigationDrawer from "@/components/navigation-drawer/NavigationDrawer"
import WeneaIcon from "@/components/icons/WeneaIcon"

export default {
    name: "App",
    components: {
        NavigationDrawer,
        WeneaIcon
    },
    inject: ['$api'],
    data() {
        return {
            projectInfo: null,
        }    
    },
    computed: {
        navigationStructure() { 
            return [
                { text: 'Connections', icon: 'mdi-vector-polyline', link: '/connections', activeRegex: 'connection/' },
                { text: 'Providers', icon: 'mdi-earth', link: '/providers', activeRegex: 'provider/' },
                { id: 'loc-groups', text: 'Loc. Groups', icon: 'mdi-folder-marker', link: '/location-groups', activeRegex: 'location-group/' },
                { text: 'Models', icon: 'mdi-format-list-bulleted',
                    childs: [
                        { text: 'Locations', icon: 'mdi-map-marker', link: '/locations', activeRegex: '(chargepoint\/).*|(location\/).*' }, 
                        { text: 'Sessions', icon: 'mdi-triangle-wave', link: '/sessions', activeRegex: 'session/' },
                        { text: 'Cdrs', icon: 'mdi-credit-card-outline', link: '/cdrs' },
                        { text: 'Commands Start', icon: 'mdi-console-line', link: '/commands-start', activeRegex: 'commands-start/' },
                        { text: 'Commands Stop', icon: 'mdi-console-line', link: '/commands-stop', activeRegex: 'commands-stop/' },
                        { text: 'Tariffs', icon: 'mdi-tag-text-outline', link: '/tariffs', activeRegex: 'tariffs/' },
                        { text: 'Tokens', icon: 'mdi-key', link: '/tokens' },
                        { text: 'Real-Time Authorizations', icon: 'mdi-key-wireless', link: '/real-time-authorizations' },
                    ]
                },
                { id: 'operations', text: 'Operations', icon: 'mdi-archive-search-outline',
                    childs: [
                        { text: 'Charge request errors', icon: 'mdi-badge-account-alert', link: '/charge-request-errors' },
                        { id: 'session-errors', text: 'Session errors', icon: 'mdi-battery-alert-variant-outline', link: '/session-errors' },
                        { text: 'Wenea tokens', icon: 'mdi-key', link: '/wenea-tokens' },
                        { text: 'Cdr processes', icon: 'mdi-calculator-variant', link: '/cdr-processes' },
                        { text: 'Billing Dashboard', icon: 'mdi-view-dashboard', link: '/billing-dashboard' },
                        { text: 'Invoices', icon: 'mdi-receipt-text-check-outline', link: '/invoices' },
                    ]
                },
                { text: 'Reports', icon: 'mdi-file-multiple',
                childs: [
                        { text: 'Locations amount by provider', icon: 'mdi-file-marker', link: '/locations-amount' }, 
                        { text: 'Sessions by provider', icon: 'mdi-account-details', link: '/sessions-by-provider' }, 
                        { text: 'Pending locations export to Core', icon: 'mdi-database-export', link: '/pending-locations-export-to-core' },
                    ]
                },
                { text: 'Jobs', icon: 'mdi-book-clock',
                    childs: [
                        { text: 'Out session queues', icon: 'mdi-tray-full', link: '/out-session-queues' }, 
                        { text: 'Cron jobs', icon: 'mdi-calendar-check', link: '/cron-jobs' },
                    ]
                },
                { text: 'Settings', icon: 'mdi-cog',
                    childs: [
                        { text: 'General', icon: 'mdi-cog', link: '/settings' }, 
                        { id: 'wenea', text: 'Wenea', link: '/wenea' },
                        { text: 'Users', icon: 'mdi-account-multiple', link: '/users' },
                        { text: 'Users Audit Logs', icon: 'mdi-tune-vertical', link: '/users-actions' },
                        { text: 'Export Locations to Core', icon: 'mdi-database-export', link: '/export-locations-to-core' },
                        { text: 'HTTP Internal Logs', icon: 'mdi-console', link: '/http-internal-logs' },
                        { text: 'Sentry Logs', icon: 'mdi-open-in-new', 
                            link: `https://sentry.io/organizations/wenea-a3/issues/?environment=${process.env.VUE_APP_SUB_ENV}&project=4504054897704960&query=is%3Aunresolved&statsPeriod=7d`,
                            external: true},
                        { text: 'Searcher', icon: 'mdi-magnify', link: '/searcher' },
                        { text: 'Tools', icon: 'mdi-tools', link: '/tools' },
                        { text: 'Failured requests', icon: 'mdi-alert-octagon', link: '/failured-requests' },
                    ]
                }
            ]
        },
        version(){ return this.projectInfo ? this.projectInfo.version : "-" },
        user() { return this.$store.state.user },
    },
    mounted() {
        this.$api.get('info')
            .then(resp => this.projectInfo = resp.data)
            .catch(error => console.log(`[Get APP Info] ${error}`))
    },
    sockets: {
        connect: function() {
            console.log("socket connected")
        },
        customEmit: function() {
            console.log(
                'this method was fired by the socket server. eg: io.emit("customEmit", data)'
            )
        }
    },
    methods: {
        logout() {
            this.$store.dispatch("logout")
        },
    }
}
</script>
<style scope>
    .pointer-on-hover table > tbody tr:hover {
        cursor: pointer !important;
    }
    .td-linkto {
        padding: 0 !important;
    }
    .v-list-item__prepend > .v-icon {
        opacity: 1 !important;
    }
    .app-alert .v-alert__prepend {
        align-self: center;
    }
</style>