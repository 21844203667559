<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-card
      class="pa-2"
      variant="outlined"
    >
      <v-card-text>
      
        <v-row class="mt-0">
          <!-- id -->
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-text-field
              v-model="formData.id"
              label="Id."
              density="compact"
              disabled
              variant="underlined"
            ></v-text-field>
          </v-col>

          <!-- ocpi_id -->
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              v-model="formData.ocpi_id"
              label="OCPI Id."
              disabled
              density="compact"
              variant="underlined"
            ></v-text-field>
          </v-col>

          <!-- name-->
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <v-text-field
              v-model="formData.name"
              label="Name"
              :rules="[v => v ? v.length <= 32 || 'Name must have a maximum of 32 characters' : true]"
              density="compact"
              variant="underlined"
            ></v-text-field>
          </v-col>

          <!-- type -->
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-select
              v-model="formData.type"
              :items="tariffTypes"
              clearable
              label="Type"
              density="compact"
              variant="underlined"
            ></v-select>
          </v-col>

          <!-- tariff_alt_url -->
          <v-col
            cols="12"
            sm="6"
            md="8"
          >
            <v-text-field
              v-model="formData.url"
              label="Url"
              density="compact"
              variant="underlined"
            ></v-text-field>
          </v-col>
        </v-row>
          
        <v-row class="mt-0">
          <v-col class="pb-2"
            cols="12"
            sm="12"
            md="12"
          >
            <h5 class="item-label">Price</h5>
          </v-col>

          <!-- currency -->
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-select
              v-model="formData.currency"
              label="Currency"
              :items="['EUR', 'GBP']"
              :rules="[v => !!v || 'Currency is required']"
              clearable
              density="compact"
              variant="underlined"
            ></v-select>
          </v-col>

          <!-- price -->
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              class="pl-2"
              v-model.number="formData.price"
              :append-icon="formData.currency ? `mdi-currency-${formData.currency.toLowerCase()}` : ''"
              type="number"
              :rules="[v => v !== null && v !== '' && typeof v !== 'undefined' || 'Price is required']"
              label="Price"
              density="compact"
              variant="underlined">
            </v-text-field>
          </v-col>
          
          <!-- VAT -->
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              class="pl-2"
              v-model.number="formData.vat"
              suffix="%"
              type="number"
              label="VAT"
              density="compact"
              clearable
              variant="underlined"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- tariff_alt_text -->
        <v-row>
          <v-col class="pb-2"
            cols="12"
            sm="12"
            md="12"
          >
            <h5 class="item-label">Description</h5>
          </v-col>
        </v-row>
        <template v-if="formData.descriptions">
              <v-row v-for="(_, index) in formData.descriptions" :key="index" class="align-end my-n3">
                  <v-col 
                    cols="12"
                    sm="4"
                    md="2"
                  >
                    <v-row class="align-end ma-0">
                      <span class="text-caption mr-2" style="line-height:34px;">{{ index + 1 }} - </span>
                      <v-select
                        v-model="formData.descriptions[index].language"
                        label="Language"
                        :items="['EN', 'ES']"
                        clearable
                        hide-details
                        variant="underlined"
                      ></v-select>
                    </v-row>
                  </v-col>

                  <v-col 
                    cols="12"
                    sm="8"
                    md="10"
                  >
                    <v-row class="align-end ma-0">
                      <v-text-field
                          v-model="formData.descriptions[index].text"
                          label="Text"
                          hide-details
                          variant="underlined"
                      ></v-text-field>
                      <v-btn class="ml-2"
                        variant="text"
                        icon="mdi-close"
                        size="small"
                        @click="() => formData.descriptions.splice(index, 1)" 
                      />
                    </v-row>
                  </v-col>
              </v-row>
              <v-row :set="newItemIndex = formData.descriptions.length" class="align-end mb-n3">
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-row class="align-end ma-0">
                      <span class="text-caption pr-3" style="line-height:34px;">{{ newItemIndex + 1 }} - </span>
                      <v-btn
                        variant="text"
                        max-width="auto"
                        @click="() => formData.descriptions.push({ language: null, text: null })">
                        <span color="grey">Add +</span>
                      </v-btn>
                    </v-row>
                  </v-col>
              </v-row>
        </template>

      </v-card-text>
    </v-card>

    <v-card 
      class="mt-4 pa-2"
      variant="outlined"
    >
      <h4 class="pt-3 pl-5">Configurations</h4>
      <tariff-configurations-edit ref="tariffConfigForm" :value="tariffConfiguration"></tariff-configurations-edit>
    </v-card>

    <div class="text-right mt-4">
        <v-btn 
            class="cancel-btn mr-2"
            variant="tonal"
            @click="$emit('cancel')"
        >
          CANCEL
        </v-btn>
        <v-btn 
            class="accent"
            color="accent"
            :disabled="!valid" 
            @click="save(formData, tariffConfiguration)"
        >
          <v-icon left>mdi-content-save</v-icon> SAVE
      </v-btn>
    </div>
  </v-form>
</template>
<script>
import AppBar from "@/components/AppBar"
import TariffConfigurationsEdit from "@/components/tariff/TariffConfigurations.edit"

export default {
    name: "tariff-edit-dialog",
    components: {
      AppBar,
      TariffConfigurationsEdit
    },
    props: {
      value: {
        required: false
      }
    },
    inject: ['$api'],
    data() {
        return {
          valid: true,
          formData: {
            id: null,
            ocpi_id: null,
            name: null,
            type: null,
            url: null,
            currency: null,
            price: null,
            vat: null,
            descriptions: [],
          },
          tariffConfiguration: {},
          tariffTypes: [
              "AD_HOC_PAYMENT",
              "PROFILE_CHEAP",
              "PROFILE_FAST",
              "PROFILE_GREEN",
              "REGULAR",
          ],
        }
    },
    created(){
      this.loadFormData(this.value)
    },
    watch: {
      value(tariff){
        this.loadFormData(tariff)
      }
    },
    methods: {
      loadFormData(tariff){
        if(!tariff) {
          return
        }

        // tariff data is separated from tariff_configuration
        const ocpiTariff = (({tariff_configuration, ...tariff}) => tariff) (tariff)
        this.formData = this.ocpiTariffToFormData(ocpiTariff)

        this.tariffConfiguration = tariff.tariff_configuration || {}
      },
      ocpiTariffToFormData(ocpiTariff) {
        return {
          id: ocpiTariff.id,
          ocpi_id: ocpiTariff.ocpi_id,
          name: ocpiTariff.name,
          type: ocpiTariff.type,
          url: ocpiTariff.tariff_alt_url,
          currency: ocpiTariff.currency,
          price: ocpiTariff.elements?.a[0]?.price_components[0]?.price,
          vat: ocpiTariff.elements?.a[0]?.price_components[0]?.vat,
          descriptions: ocpiTariff.tariff_alt_text.a,
        }
      },
      formDataToOcpiTariff(formData){

        const ocpiTariff = { 
          provider_id: 1,
          country_code: 'ES',
          party_id: 'WEN',
          name: formData.name,
          type: formData.type,
          tariff_alt_url: formData.url,
          currency: formData.currency,
          tariff_alt_text: formData.descriptions,
          elements: [
            { price_components: [{ type: "ENERGY", price: formData.price, step_size: 1 }] }
          ]  
        }

        if ( formData.id ) { ocpiTariff.id = formData.id }
        if ( formData.ocpi_id ) { ocpiTariff.ocpi_id = formData.ocpi_id }

        if ( formData.vat ) {
          ocpiTariff.elements.a[0].price_components[0].vat = formData.vat
        }

        return ocpiTariff
      },
      save(formData, tariffConfiguration){
       
        if (!this.$refs.form.validate() || !this.$refs.tariffConfigForm.validate()) { return }

        const ocpiTariff = this.formDataToOcpiTariff(formData)
        const data = { ...ocpiTariff, tariffConfiguration }
        
        if (data.id) {
          this.$api.put(`/tariffs/${data.id}`, data)
            .then(() => {
              this.showSuccess("Tariff changes have been saved and their relationships have been updated.")
              this.$emit('updated', data)
            })
            .catch(this.showError)
        } else {
          this.$api.post(`/tariffs`, data)
            .then(resp => {
              this.showSuccess("Tariff and its relationships have been created.")
              this.$emit('updated', resp.data)
            })
            .catch(this.showError)
        }
      }
    }
}
</script>

<style scoped>
  .elements-expansion-panel-header > *:not(.v-expansion-panel-title__icon) {
    flex: 0 1 auto!important;
  }
  :deep(.elements-expansion-panel-header)>.v-expansion-panel-title__icon {
    margin-left: 0;
  }
  :deep(.elements-expansion-panel-header-add)>.v-expansion-panel-title__icon {
    margin-left: auto;
  }

  :deep(div).v-expansion-panel::before{
    box-shadow: none !important;
  }
  :deep(.v-expansion-panels).v-expansion-panel{
    border: thin solid rgba(0, 0, 0, 0.12);
    border-bottom: none;
    border-top: none;
  }
  :deep(div).v-expansion-panel:first-child{
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }
  :deep(div).v-expansion-panel:last-child{
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
  :deep(.v-expansion-panel-title){
      height: 48px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
  }
  :deep(.v-expansion-panel--active)>.v-expansion-panel-title {
      min-height: 48px;
  }
  :deep(.v-expansion-panel--active:not(:first-child),).v-expansion-panel--active+ .v-expansion-panel {
          margin-top: 0px;
  }
  .cancel-btn {
    color: black !important;
    border: 1px solid #ccc!important;
  }
  .item-label {
    font-size: 14px!important;
  }

  .slide-fade-enter-active {
  transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(-10px);
    opacity: 0;
  }
</style>